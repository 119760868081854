import React from 'react'
import { IconsProps } from 'msp-integrations'

const ArrowUpIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 141.73 141.73'
    >
      <path d="M70.87 49.23 29.8 90.37l2.13 2.12 38.94-39.01 38.93 39.01 2.13-2.12z" />
    </svg>
  )
}

export default ArrowUpIcon
